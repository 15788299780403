<template>
  <div id="checkinTakeoff" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page box-S4 T-size-36">Check-in Flight Take Off</div>
      </div>

      <div class="box-S4 flex-between noPadding">
        <div class="box-S2">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="0"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="datefrompicker"
                class="hideMessage"
                append-icon="mdi-calendar-range"
                label="Select Date"
                readonly
                dense
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="datefrompicker"
              @change="datadatefromcalender"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="box-S2">
          <v-select
            class="hideMessage"
            :items="itemsListDeparture"
            v-model="selectDeparture"
            item-text="ap_name"
            item-value="ap_id"
            label="Departure From"
            return-object
            @change="departureFromChange"
          ></v-select>
        </div>
      </div>
    </div>

    <CompListCheckin
      ref="CompListCheckin"
      :Service="'takeoff'"
      :ap_id="selectDeparture.ap_id"
    />
    <!-- <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div> -->
  </div>
</template>

<script>
import CompListCheckin from "../components/checkin/CompListCheckin";
import feathersClientUOA from "../plugins/feathers-client-uoa";
export default {
  data: () => ({
    datefrompicker: new Date().toISOString().substr(0, 10),
    menu: false,
    itemsListDeparture: [],
    selectDeparture: {},
  }),

  components: {
    CompListCheckin,
  },
  async mounted() {
    await this.renderListDeparture();
  },
  methods: {
    async datadatefromcalender(dataDate) {
      this.menu = false;
      await this.$refs.CompListCheckin.renderListCheckinbyDate(dataDate);
    },
    async renderListDeparture() {
      try {
        var data = await feathersClientUOA.service("mastercommand").find({});
        var airports = data[0].airport.filter((x) => x.ap_checkin == true);
        airports.forEach((element) => {
          this.itemsListDeparture.push(element);
        });
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
      await this.selectDepartureFrom();
    },
    async selectDepartureFrom() {
      try {
        let departureChange =
          await this.$LocalStorageService.getDepartureChange();
        if (departureChange == null) {
          this.selectDeparture = {
            ap_id: this.itemsListDeparture[0].ap_id,
            ap_name: this.itemsListDeparture[0].ap_name,
          };
        } else {
          let obj = JSON.parse(departureChange);
          this.selectDeparture = { ap_id: obj.ap_id, ap_name: obj.ap_name };
        }
      } catch (error) {
        console.log(
          "SelectListCustomer ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
            error
        );
      }
    },
    async departureFromChange(arg) {
      this.$LocalStorageService.setDepartureChange(
        this.selectDeparture.ap_id,
        this.selectDeparture.ap_name
      );
      await this.$refs.CompListCheckin.renderListCheckinbyDate(this.datefrompicker);
    },
  },
};
</script>
<style>
</style>